import {
  StarRating,
  TriggerButton,
  Image,
  Link,
  lineClamp,
  Label,
  Heading,
  Paragraph,
  Icon,
} from '@loveholidays/design-system';
import { DontHydrate } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useState, useMemo, Fragment } from 'react';

import { ReturnToOfferItem, ReturnToOfferItemSunrise } from './types';
import { Image as ImageType } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';
import { useImageFallbackSrc } from '@Core/hooks/useImageFallbackSrc';
import { EcommerceProduct } from '@Core/tracking/types';
import { useDate } from '@Dates/useDateHook';
import { useProductClickTracking, useProductImpressionTracking } from '@Tracking';
import { slideUp } from '@UX/keyframes';

const isAuroraImage = (image: any): image is ImageType => image && 'url' in image;

export const ReturnToOfferCard: React.FC<ReturnToOfferItem | ReturnToOfferItemSunrise> = ({
  departureDate,
  forward,
  hotelImage,
  hotelName,
  hotelStarRating,
  inbound,
  outbound,
  masterId,
  nights,
}) => {
  const { t } = useTranslation();
  const { site } = useAppContext();
  const imageFallbackSrc = useImageFallbackSrc();
  const { formatCalendarDate } = useDate();
  const [isOpened, setOpened] = useState(true);
  const trackProductClick = useProductClickTracking();
  const trackingItem: EcommerceProduct = {
    masterId,
    name: hotelName,
    nights,
    position: 1,
  };

  const ref = useProductImpressionTracking({
    ...trackingItem,
    list: 'ReturnToOffer',
  });

  const mainImage = useMemo(() => {
    if (!hotelImage) {
      return imageFallbackSrc;
    }

    if (isAuroraImage(hotelImage)) {
      return hotelImage.url;
    }

    const hasImage = hotelImage?.location && hotelImage?.hash;

    return hasImage
      ? // Normally this happens in Aurora, but we need to do this here as the data is in local storage
        `${site.assetDomain}/images/holidays/${hotelImage.hash}/${hotelImage.location.replace(
          /\.jpg$/,
          '-0.jpg',
        )}`
      : imageFallbackSrc;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelImage]);

  const infoItems = useMemo(
    () =>
      [
        outbound && inbound
          ? t('returnToOffer.flyingFrom', {
              from: outbound?.airport,
              to: inbound?.airport,
            })
          : undefined,
        formatCalendarDate(departureDate),
        t('nightsLabel', { count: nights }),
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [outbound, inbound, departureDate, nights],
  );

  return (
    <section
      data-id="return-to-offer"
      ref={ref}
      sx={{
        position: 'fixed',
        left: ['3xs', 'xs', 'auto'],
        right: ['3xs', 'xs'],
        bottom: ['3xs', 'xs'],
        zIndex: 'stickyContent',
        backgroundColor: 'backgroundWhite',
        borderRadius: '8',
        maxWidth: [null, null, '496px'],
        boxShadow: 'elevationFixed',
        padding: 'xs',
        animation: `${slideUp} .3s forwards`,
        willChange: 'transform',
      }}
    >
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 'xs',
        }}
      >
        <Heading
          as="h2"
          variant="small"
        >
          {t('returnToOffer.pickUpWhereYouLeftOff')}
        </Heading>

        <TriggerButton onTrigger={() => setOpened(!isOpened)}>
          <Label
            variant="smallbold"
            sx={{ color: 'textPrimarydark' }}
          >
            {isOpened ? t('toggle.hide') : t('toggle.show')}
          </Label>
        </TriggerButton>
      </div>

      <Link
        aria-label={t('returnToOffer.returnToOffer')}
        href={forward}
        onClick={() => trackProductClick([trackingItem], { list: 'ReturnToOffer' })}
        sx={{
          textDecoration: 'none',
          display: isOpened ? 'block' : 'none',
          marginTop: '3xs',
        }}
      >
        <section
          sx={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Image
            sx={{
              marginRight: 'xs',
              flex: '0 0 100px',
              height: 'auto',
              objectFit: 'cover',
              borderRadius: '8',
            }}
            src={mainImage}
            alt={hotelName}
            width={100}
            height={100}
          />

          <div sx={{ flexGrow: 1 }}>
            {!!hotelStarRating && (
              <DontHydrate>
                <StarRating
                  rating={hotelStarRating}
                  sx={{
                    color: 'iconDefault',
                  }}
                />
              </DontHydrate>
            )}

            <Heading
              as="h3"
              variant="small"
              sx={{
                ...lineClamp(2),
                marginBottom: '4xs',
              }}
            >
              {hotelName}
            </Heading>

            <Paragraph
              variant="extrasmall"
              sx={{ color: 'textDimmedmedium' }}
            >
              {infoItems.map((item, index) => (
                <Fragment key={index}>
                  {index > 0 && ' · '}
                  <span
                    sx={
                      { textWrap: index > 0 ? 'nowrap' : 'wrap' } as any
                      // theme-ui is not aware of "text-wrap" :(
                    }
                  >
                    {item}
                  </span>
                </Fragment>
              ))}
            </Paragraph>
          </div>
          <div sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              name="Actions/MoveNext"
              aria-label={t('returnToOffer.returnToOffer')}
              size="36"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginLeft: [null, null, 'xl'],
                color: 'iconDefault',
              }}
            />
          </div>
        </section>
      </Link>
    </section>
  );
};
